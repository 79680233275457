import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { COOKIE_KEY } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { getCookie, setModalShowCookie } from 'shared/helpers/cookie';
import { Button } from 'shared/ui';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

type NotCompletedProfilenModalProps = {
  isVisible: boolean;
  onClose: () => void;
};

export const NotCompletedProfileModal: FC<NotCompletedProfilenModalProps> = ({ isVisible = false, onClose }) => {
  const { t } = useTranslation('utils');
  const router = useRouter();

  const handleClick = () => {
    setModalShowCookie(COOKIE_KEY.notCompletedProfileModal, 'true');
    onClose();
    router.push(routes.profileSteps);
  };

  const isNotCompletedProfileModalShown = process.browser && getCookie(COOKIE_KEY.notCompletedProfileModal);

  if (isNotCompletedProfileModalShown) {
    return null;
  }

  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <Container>
        <Title>{t('modals.notCompletedProfileModal.title')}</Title>
        <Description>{t('modals.notCompletedProfileModal.description')}</Description>
        <SubmitButton block onClick={handleClick}>
          {t('modals.notCompletedProfileModal.buttonYes')}
        </SubmitButton>
        <NoButton onClick={onClose}>{t('modals.notCompletedProfileModal.buttonNo')}</NoButton>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  text-align: center;
`;
const Title = styled.h3`
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 15px;
  white-space: pre-line;
`;
const Description = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 25px;
`;

const SubmitButton = styled(Button)`
  margin-top: 30px;
`;

const NoButton = styled.div`
  margin-top: 10px;
  color: var(--purple);
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }
`;
