import { unwrapResult } from '@reduxjs/toolkit';
import { Schedule } from 'app/assets/svg';
import InfoIcon from 'app/assets/svg/InfoIcon';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ym from 'react-yandex-metrika';
import { Category, InvoiceStatusEnum, Rubric, User, UserCategoryMeta } from 'shared/api';
import { CONDITION_DESKTOP, ENVIRONMENT_TYPE, XPlatformHeader } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { captureError, checkCanUseFreeToken, findFirstNotUsedPromocode } from 'shared/helpers';
import { getUserConsultationsRubricMap } from 'shared/helpers/getUserConsultationsRubricMap';
import { Button, ImageWithRetina, Tip } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import { selectors as profileSelectors } from 'store/ducks/profile';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

import { ConsultationPaymentModal } from './ui';

interface PublicExpertConsultationsProps {
  profile: User;
  rubrics: Rubric[];
  categories: Category[];
}

interface PaymentModalState {
  price: number;
  theme: string;
  userCategoryMeta: UserCategoryMeta;
}

const sendAnalyticsData = () => {
  if (ENVIRONMENT_TYPE === 'production') {
    ym('reachGoal', ['firstblood']);
  }
};

export const PublicExpertConsultations: FC<PublicExpertConsultationsProps> = ({ profile, rubrics, categories }) => {
  const { t } = useTranslation('expert.page');

  const hasFreeConsultations = profile.hasFreeConsultations && profile.freeConsultationsRemain > 0;
  const categoriesMap = new Map<number, Category>();
  const rubricsMap = new Map<number, Rubric>();

  rubrics.forEach((r) => rubricsMap.set(r.id, r));
  categories.forEach((c) => categoriesMap.set(c.id, c));

  const consultationsRubrics = useMemo(
    () => getUserConsultationsRubricMap(profile.categories, categoriesMap),
    [profile.categories, categoriesMap],
  );

  const [paymentModalState, setPaymentModalState] = useState<PaymentModalState>({} as PaymentModalState);
  const userPromocodes = useSelector(profileSelectors.selectUserPromocodes);
  const [canUseFreeToken, setCanUseFreeToken] = useState(false);
  const isUserAuthorized = useAppSelector(profileSelectors.selectIsAuthentication);
  const isVisiblePaymentModal = useAppSelector(selectors.app.selectIsVisiblePaymentModal);
  const dispatch = useAppDispatch();
  const hasAnyConsultations = useAppSelector(selectors.profile.selectHasAnyConsultations);
  const notUsedPromocode = findFirstNotUsedPromocode(userPromocodes);

  const router = useRouter();
  const chatRooms = useAppSelector(selectors.chatRooms.selectChatRooms);

  const redirectUrl = useMemo(() => {
    const chatRoomWithThisExpert = chatRooms?.find((room) => room.expertId === profile.id);
    let redirectUrl = '';
    if (typeof window !== 'undefined') {
      redirectUrl = chatRoomWithThisExpert
        ? `${window.location.origin}${routes.chat}?activeTab=consult&roomId=${chatRoomWithThisExpert.id}`
        : `${window.location.origin}${routes.chat}?activeTab=consult&expertId=${profile.id}`;
    }
    return redirectUrl;
  }, [chatRooms, profile]);

  const onPayment = (state: PaymentModalState) => () => {
    setPaymentModalState({ ...state });
    if (!isUserAuthorized) {
      return dispatch(actions.app.showAuthModal(true));
    }
    dispatch(actions.app.showPaymentModal());
  };

  const onPayWithToken = async () => {
    if (notUsedPromocode) {
      try {
        const action = await dispatch(
          actions.consultations.createConsultationFromUserMetaWithPromocodeAsync({
            dto: {
              userCategoryMetaId: paymentModalState.userCategoryMeta.id,
              userPromocodeId: notUsedPromocode.id,
              redirectUrl,
            },
            options: {
              headers: {
                'X-Platform': 'web',
              },
            },
          }),
        );
        const data = unwrapResult(action);

        dispatch(actions.chatRooms.addChatRoom(data.chatRoom));
        dispatch(actions.profile.fetchSelfPromocode());
        if (!hasAnyConsultations) {
          sendAnalyticsData();
        }
        await router.push({
          pathname: routes.chat,
          query: {
            activeTab: 'consult',
            roomId: data.chatRoomId,
          },
        });
      } catch (e: any) {
        captureError(e);
        toast.error(e.message || t('notSuccessPayWithToken'));
      }
    }
  };

  const onPayWithMoney = async () => {
    try {
      const action = await dispatch(
        actions.consultations.createConsultationFromUserMetaAsync({
          dto: {
            userCategoryMetaId: paymentModalState.userCategoryMeta.id,
            redirectUrl,
          },
          options: {
            headers: {
              'X-Platform': XPlatformHeader,
              'X-Source': 'expertProfile',
            },
          },
        }),
      );
      const data = unwrapResult(action);

      if (!hasAnyConsultations) {
        sendAnalyticsData();
      }
      const paymentUrl = data.invoices.find((invoice) => invoice.invoice?.status === InvoiceStatusEnum.Pending)?.invoice
        ?.checkoutUrl;
      if (paymentUrl) {
        setTimeout(() => {document.location.href = paymentUrl;},250);
      }

      if (ENVIRONMENT_TYPE === 'production') {
        ym('reachGoal', ['consultation_pay']);
        if (window && window.gtag) {
          window.gtag('event', 'consultation_pay');
        }
      }

      onClosePaymentModal();
    } catch (e: any) {
      captureError(e);
      toast.error(e.message || t('somethingWrong'));
    }
  };

  const onClosePaymentModal = () => {
    dispatch(actions.app.hidePaymentModal());
  };

  const handleSelectDateZoomMeeting = async (categoryId: number, categoryName: string, consultationPrice: number) => {
    await dispatch(
      actions.bookingZoom.setConsultation({
        expertId: profile.id,
        expertFirstName: profile.firstName || '',
        expertLastName: profile.lastName || '',
        categoryName,
        consultationPrice,
        categoryId,
      }),
    );
    dispatch(actions.bookingZoom.setMskTime(null));
    dispatch(actions.bookingZoom.setDate(null));
    router.push(routes.booking);
  };

  useEffect(() => {
    setCanUseFreeToken(checkCanUseFreeToken(userPromocodes, profile));
  }, [userPromocodes]);

  return (
    <>
      <Container id="PublicExpertConsultations">
        <Title>{t('expertConsultations')}</Title>
        {!profile.isContractAccepted && (
          <NotContractInfo>
            <div>
              <InfoIcon />
            </div>
            <div>{t('notContractInfo')}</div>
          </NotContractInfo>
        )}
        {Array.from(consultationsRubrics.keys()).map((rubricId) => (
          <RubricContainer key={rubricId}>
            <RubricTitle>{rubricsMap.get(rubricId)!.name}</RubricTitle>

            {consultationsRubrics.get(rubricId)!.map((category) => (
              <CategoryContainer key={category.categoryId}>
                <CategoryName>
                  <CategoryNameContainer>
                    <Name>{category.category.name}</Name>
                    <Dots />
                  </CategoryNameContainer>
                </CategoryName>

                <CategoryPrice>
                  <span>{t('averagePrice', { price: category.price })}</span>
                  <ButtonContainer>
                    <MobileButton
                      leftIcon={<Schedule />}
                      color="gray"
                      disabled={!profile.isContractAccepted}
                      onClick={() =>
                        handleSelectDateZoomMeeting(category.categoryId, category.category.name, category.price)
                      }
                    />

                    <DesktopButton
                      disabled={!profile.isContractAccepted}
                      size="small"
                      bordered
                      color="gray"
                      onClick={() =>
                        handleSelectDateZoomMeeting(category.categoryId, category.category.name, category.price)
                      }
                    >
                      {t('selectTime')}
                    </DesktopButton>

                    {!!hasFreeConsultations && canUseFreeToken && (
                      <CoinContainer $disabled={!profile.isContractAccepted}>
                        <Tip text={profile.isContractAccepted ? t('canPayWithToken') : ''}>
                          <ImageWithRetina src="/img/coin.png" alt="coin" />
                        </Tip>
                      </CoinContainer>
                    )}
                  </ButtonContainer>
                </CategoryPrice>
              </CategoryContainer>
            ))}
          </RubricContainer>
        ))}
      </Container>
      <Modal isVisible={isVisiblePaymentModal} onClose={onClosePaymentModal}>
        <ConsultationPaymentModal
          price={paymentModalState.price}
          theme={paymentModalState.theme}
          onRefuse={onClosePaymentModal}
          onPayWithMoney={onPayWithMoney}
        />
      </Modal>
    </>
  );
};

const Container = styled.div`
  ${CONDITION_DESKTOP} {
    max-width: 700px;
  }
`;

const RubricContainer = styled.div`
  padding-bottom: 30px;

  ${CONDITION_DESKTOP} {
    padding-bottom: 60px;
  }
`;

const Title = styled.div`
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: var(--text);
  padding-bottom: 20px;

  ${CONDITION_DESKTOP} {
    padding-bottom: 40px;
    font-size: 22px;
  }
`;

const RubricTitle = styled(Title)`
  font-size: 17px;
  line-height: 1.1;
  letter-spacing: normal;
  padding-bottom: 15px;

  ${CONDITION_DESKTOP} {
    font-size: 19px;
  }
`;
const CategoryContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px 0;
  max-width: 80vw;

  ${CONDITION_DESKTOP} {
    max-width: unset;
  }
`;
const CategoryName = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: var(--text);
  display: flex;
  align-items: center;
  width: 56%;
  position: relative;
  background-color: var(--white);

  ${CONDITION_DESKTOP} {
    font-size: 15px;
  }

  & > div {
    overflow: hidden;
  }
`;

const CategoryNameContainer = styled.div`
  display: flex;
  width: 100%;
`;

const CategoryPrice = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: space-between;
  text-align: right;

  ${CONDITION_DESKTOP} {
    gap: 20px;
  }

  & > span {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.1;
    letter-spacing: normal;
    color: var(--gray);
    width: 60px;
  }
`;
const Dots = styled.span`
  color: var(--gray3);
  width: 100%;

  :before {
    content: '.............................................................................................................................................................';
  }
`;
const Name = styled.span`
  ${CONDITION_DESKTOP} {
    white-space: nowrap;
  }
`;

const DesktopButton = styled(Button)`
  display: none;
  padding: 5px 10px;
  color: var(--purple);
  background-color: var(--white);
  border: 1px solid var(--purple);

  ${CONDITION_DESKTOP} {
    display: unset;
  }
`;
const MobileButton = styled(Button)`
  display: unset;
  padding: 5px 16px;
  justify-content: center;
  height: 25px;
  background-color: var(--gray7);

  :hover {
    background-color: var(--gray3);
  }

  & > div {
    margin: 0;
  }

  & > span {
    display: none;
  }

  ${CONDITION_DESKTOP} {
    display: none;
  }
`;

const CoinContainer = styled.div<{ $disabled: boolean }>`
  display: none;
  opacity: ${({ $disabled }) => ($disabled ? '0.7' : '1')};

  ${CONDITION_DESKTOP} {
    display: unset;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const NotContractInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: var(--gray7);
  padding: 10px;
  border-radius: 8px;
  font-size: 11px;
  margin-bottom: 20px;

  ${CONDITION_DESKTOP} {
    margin-bottom: 40px;
  }
`;
