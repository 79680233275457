import { api, ChatConsultationOffer, ChatRoom, Consultation, Message } from 'shared/api';

export type LinkType = {
  text: string;
  href: string;
};

export type LoadingStatus = 'idle' | 'pending' | 'fulfilled' | 'rejected';
export type MessageExtended = Partial<Omit<Message, 'type'>> & {
  status?: 'error' | 'pending' | 'sent';
  type?: Message['type'] | 'temporary';
  imageUrl?: string;
};

export type MessageVariants = 'me' | 'companion' | 'system';

export interface ChatRoomExtended extends ChatRoom {
  isTempRoom?: boolean;
  isCompanionWritten?: boolean;
}

export interface ConsultationWithOffer extends Consultation {
  consultationOffer?: ChatConsultationOffer;
}

export interface GetMessagesAxiosResponse {
  count: number;
  data: Message[];
  page: number;
  pageCount: number | null;
  total: number;
}

export interface IErrors {
  title: string;
  note: string;
}

export interface Params {
  fields?: Array<string>;
  s?: string;
  filter?: Array<string>;
  or?: Array<string>;
  sort?: Array<string>;
  join?: Array<string>;
  limit?: number;
  offset?: number;
  page?: number;
  cache?: number;
  options?: any;
}

export type Services = typeof api;

export type ExtraParamsThunkType<T> = {
  extra: { api: Services };
  rejectValue: T;
};

export type ContractSignSmsCodePayload = {
  smsCode: string;
  timestampEnterSmsCode: string;
};

export enum Steps {
  photo = 'photo',
  about = 'about',
  themes = 'themes',
  variant = 'variant',
  props = 'props',
  offer = 'offer',
  done = 'done',
}
