import { useAppDispatch } from 'hooks/redux';
import Head from 'next/head';
import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { UserSendedEmailsEnum, UserStepsEnum } from 'shared/api';
import { AFTER_3_DAYS, AFTER_3_HOURS, LIMIT_CHANNEL_NEWS } from 'shared/common/constants';
import { LinkType } from 'shared/common/types';
import { captureError, isExpertAndContractNotAccepted, isTimeToShow } from 'shared/helpers';
import { actions, selectors as profileSelectors } from 'store/ducks';
import styled from 'styled-components';
import { Footer, Header, Hellobar, Npspoll } from 'widgets/common';
import { NotCompletedProfileModal, OneStepConsultationModal } from 'widgets/modals';

interface MainLayoutProps {
  head: {
    title: string;
    ogImage?: string;
  };
  navbar?: LinkType[];
  fullWidth?: boolean;
  isLoginButtonVisible?: boolean;
  hasFooter?: boolean;
  hasHellobar?: boolean;
}

export const MainLayout: FC<MainLayoutProps> = ({
  head,
  navbar,
  children,
  isLoginButtonVisible = true,
  hasFooter,
  // fullWidth,
  hasHellobar = true,
}) => {
  const [showHellobar, setShowHellobar] = useState(hasHellobar);
  const [isShowNotCompletedProfileModal, setShowNotCompletedProfileModal] = useState(false);
  const [isShowOneStepConsultationModal, setShowOneStepConsultationModal] = useState(false);
  useEffect(() => {
    const calcHeight = () => {
      const vHeight = process.browser && window.innerHeight;
      if (process.browser) {
        document.documentElement.style.setProperty('--v-height', `${vHeight}px`);
      }
    };
    calcHeight();
    window.addEventListener('resize', calcHeight);
    return () => {
      window.removeEventListener('resize', calcHeight);
    };
  }, []);

  const dispatch = useAppDispatch();

  const closeHellobar = () => {
    setShowHellobar(false);
    dispatch(actions.profile.setIsShowHelloBar(false));
  };

  const user = useSelector(profileSelectors.profile.selectUser);
  const isExisting3DaysEmail = user.sendedEmails?.includes(UserSendedEmailsEnum.After3Days);
  const isExisting3HoursEmail = user.sendedEmails?.includes(UserSendedEmailsEnum.After3Hours);

  const sendMailAfter3Hours = async () => {
    dispatch(actions.channelNews.sendEmailAfterRegistrationAsync('after_3_hours'));
  };

  const sendMailAfter3Days = async () => {
    dispatch(actions.channelNews.sendEmailAfterRegistrationAsync('after_3_days'));
  };

  const postAfter3HoursAfterRegistration = async () => {
    dispatch(actions.channelNews.createNewsRegistrationAsync('after_3_hours'));
  };

  const postAfter3DaysAfterRegistration = async () => {
    dispatch(actions.channelNews.createNewsRegistrationAsync('after_3_days'));
  };

  useEffect(() => {
    if (user?.id) {
      const isEmailVerified = () => user?.isEmailVerified;

      if (
        isTimeToShow(AFTER_3_HOURS, user) &&
        !isTimeToShow(AFTER_3_DAYS, user) &&
        isExpertAndContractNotAccepted(user) &&
        isEmailVerified() &&
        !isExisting3HoursEmail
      ) {
        sendMailAfter3Hours();
        postAfter3HoursAfterRegistration();
      }
      if (
        isTimeToShow(AFTER_3_HOURS, user) &&
        isTimeToShow(AFTER_3_DAYS, user) &&
        isExpertAndContractNotAccepted(user) &&
        isEmailVerified() &&
        !isExisting3DaysEmail
      ) {
        sendMailAfter3Days();
        postAfter3DaysAfterRegistration();
      }
      if (isExpertAndContractNotAccepted(user) && isExisting3HoursEmail && isExisting3DaysEmail) {
        setShowOneStepConsultationModal(true);
      }
      if (
        user.steps.includes(UserStepsEnum._0checkboxexpert) &&
        user.steps.length < 9 &&
        isExisting3HoursEmail &&
        isExisting3DaysEmail
      ) {
        setShowNotCompletedProfileModal(true);
      }
    }
  }, [user?.id]);

  useEffect(() => {
    const fetchChannelNews = async () => {
      try {
        await dispatch(
          actions.channelNews.getChannelNews({
            limit: LIMIT_CHANNEL_NEWS,
            page: 1,
          }),
        );
      } catch (error: any) {
        captureError(error);
      }
    };

    fetchChannelNews();
  }, []);

  return (
    <MainContainer>
      <Head>
        <title>{head.title}</title>
        <meta
          name="description"
          content="Cервис платных онлайн-консультаций, где можно найти эксперта в любой теме и заказать у него консультацию."
        />
        <meta name="keywords" content="консультация,получить консультацию,эксперт,экспертиза,лучшие эксперты" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta property="og:title" content={head.title || 'Consulty - сервис онлайн-консультаций'} />
        <meta property="og:type" content="website" />
        {process.browser && window && <meta property="og:url" content={window.origin} />}
        <meta property="og:image" content="/img/social.jpg" />
        <meta property="og:title" content="Сервис онлайн-консультаций." />
        <meta
          property="og:description"
          content="Платные онлайн-консультации с экспертами на любые темы на сайте и в приложении."
        />
        {!head.ogImage && (
          <>
            <meta property="og:image" content="/img/social.jpg" />
            <meta property="og:image:type" content="image/jpg" />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="315" />
          </>
        )}
      </Head>
      <HellobarContainer>
        <Hellobar visible={showHellobar} onClose={closeHellobar} />
      </HellobarContainer>

      <Header links={navbar} isLoginButtonVisible />
      <Main>{children}</Main>
      {hasFooter && <Footer />}
      <Npspoll />
      <NotCompletedProfileModal
        isVisible={isShowNotCompletedProfileModal}
        onClose={() => setShowNotCompletedProfileModal(false)}
      />
      <OneStepConsultationModal
        isVisible={isShowOneStepConsultationModal}
        onClose={() => setShowOneStepConsultationModal(false)}
      />
    </MainContainer>
  );
};

const MainContainer = styled.main`
  min-height: 100vh;
  min-height: var(--v-height);
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto 1fr;
`;

const Main = styled.div`
  background-color: var(--white);
`;

const HellobarContainer = styled.div``;
