import { useTranslation } from 'next-i18next';
import React, { FC, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractFormSteps } from 'routes/contract/contract';
import { OFERTA_LINK } from 'shared/common/constants';
import { Button, Checkbox } from 'shared/ui';
import styled from 'styled-components';

import { ContractFormValues } from '../contract-form/types';

interface OfferFormProps {
  changeStepHandle: (step: ContractFormSteps) => void;
  buttonText?: string;
}

export const OfferForm: FC<OfferFormProps> = ({ changeStepHandle, buttonText }) => {
  const { t } = useTranslation('contract.page');
  const { control, watch, setValue } = useFormContext<ContractFormValues>();
  const [agencyAgreement, dataPolicyAgreement] = watch(['agencyAgreement', 'dataPolicyAgreement']);

  const onContinue = () => {
    changeStepHandle(ContractFormSteps.props);

    if (window !== undefined) {
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    if (agencyAgreement) {
      setValue('agencyAgreementTimestamp', new Date().toISOString());
      setValue('dataPolicyAgreementTimestamp', new Date().toISOString());
    }
  }, [agencyAgreement]);

  useEffect(() => {
    if (dataPolicyAgreement) {
      setValue('dataPolicyAgreementTimestamp', new Date().toISOString());
    }
  }, [dataPolicyAgreement]);

  return (
    <Container>
      <Title>
        {t('form.readTheOffer')}
        <a href={`${OFERTA_LINK}`}>{t('form.agencyAgreementOffer')}</a>
      </Title>

      <Document>
        <DocumentHeading>Термины и определения:</DocumentHeading>
        <p>
          <b>Лицензия</b> – простая (неисключительная) лицензия – неисключительное право на использование Лицензиатом
          сервиса получения онлайн-консультаций <a href="/">www.consulty.online</a> и (или) Приложения Consulty, с
          сохранением за Лицензиаром права выдачи лицензий другим лицам.
        </p>
        <p>
          <b>Сайт</b> – информационный портал Лицензиара <a href="/">www.consulty.online</a> в информационно-
          коммуникационной сети Интернет, включая все его поддомены всех уровней, разделы, в том числе «Личный кабинет»,
          через который Лицензиат посредством сервиса самостоятельно заключает договоры на оказание консультационных
          услуг с экспертами.
        </p>
        <p>
          <b>Приложение</b> – вспомогательное программное обеспечение для мобильных устройств Consulty.
        </p>
        <p>
          <b>Сервис</b> – интерфейс и модули Сайта и (или) Приложения, которые позволяют Лицензиату заключать и
          исполнять договоры на оказание консультационных услуг с экспертами.
        </p>
        <p>
          <b>Эксперт</b> – физическое лицо, в том числе обладающее статусом самозанятого плательщика налога на
          профессиональный доход, или индивидуального предпринимателя, или юридическое лицо, зарегистрированное на Сайте
          и (или) в Приложении и позиционирующее себя как лицо, обладающее компетенцией в определенных сферах.
        </p>
        <p>
          <b>Клиент</b> – лицо, зарегистрированное на Сайте и (или) в Приложении и желающее получить от Эксперта
          консультацию.
        </p>
        <p>
          <b>Тариф</b> – совокупность ценовых условий, на которых Лицензиар предлагает Лицензиату право использования
          Сайта и (или) Приложения. Перечень действующих Тарифов и их условия указаны при регистрации на Сайте и (или) в
          Приложении, а также могут быть подобраны индивидуально по запросу Лицензиата.
        </p>
        <p>
          <b>Учетная запись Лицензиата</b> – логин и пароль, указываемые Лицензиатом при регистрации на Сайте и (или) в
          Приложении и необходимые для дальнейшей авторизации и использования функционала Сайта и (или) Приложения.
        </p>
        <DocumentHeading>1. ДОПОЛНИТЕЛЬНЫЕ ПОЛОЖЕНИЯ ОБ ИСПОЛЬЗОВАНИИ САЙТА И (ИЛИ) ПРИЛОЖЕНИЯ</DocumentHeading>
        <p>
          1. В дополнение к условиям, изложенным в <a href="/oferta">договоре-оферте</a>, Лицензиат вправе
          самостоятельно на свой страх и риск осуществлять с использованием Сервиса деятельность по даче консультаций
          Клиентам.
        </p>

        <p>2. Данная деятельность осуществляется Лицензиатом самостоятельно и от своего имени.</p>
        <DocumentHeading>2. НАЧАЛО ОСУЩЕСТВЛЕНИЯ ДЕЯТЕЛЬНОСТИ В КАЧЕСТВЕ ЭКСПЕРТА</DocumentHeading>
        <p>
          2.1. Лицензиат – физическое лицо, желающее использовать функционал Сервиса, доступный Экспертам, заполняет в
          соответствующем разделе личного кабинета данные, необходимые для его идентификации, загружает для
          подтверждения его личности фото «селфи» на фоне разворота 2 – 3 страницы паспорта гражданина Российской
          Федерации, принимает условия настоящего дополнительного соглашения к лицензионному договору-оферте и
          отправляет данные Лицензиару для проверки.
        </p>
        <p>
          2.2. Как правило, в течение 48 часов, но не позднее 7 рабочих дней после проведенной проверки Лицензиар
          предоставляет Лицензиату доступ к функционалу Сервиса для ведения деятельности Эксперта.
        </p>
        <p>
          2.3. Лицензиар вправе отказать в предоставлении доступа к функционалу Сервиса для ведения деятельности
          Эксперта без объяснения причин.
        </p>
        <p>
          2.4. Лицензиат вправе разместить в Сервисе информацию о себе, подтверждающую его компетентность, в том числе
          сканы дипломов, сертификатов, свидетельств, иных документов. Лицензиар не несет ответственности за
          достоверность данных документов и не проверяет их подлинность, однако вправе удалить любые из этих документов
          или сведений без объяснения причин. В случае установления факта сообщения Экспертом о себе недостоверных
          сведений и размещения электронных образов несуществующих, скомпилированных или сфальсифицированных документов,
          Лицензиар вправе заблокировать Лицензиату доступ в личный кабинет и расторгнуть настоящий договор в
          одностороннем несудебном порядке. Кроме того, Лицензиар вправе направить соответствующие материалы в
          правоохранительные органы.
        </p>
        <p>
          2.5. Любое нарушение положений данного дополнительного соглашения Экспертом является основанием для Лицензиара
          заблокировать Лицензиату доступ в личный кабинет и расторгнуть настоящий договор в одностороннем несудебном
          порядке.
        </p>

        <DocumentHeading>3. РЕЙТИНГ ЭКСПЕРТОВ</DocumentHeading>
        <p>
          3.1 Каждый Эксперт, зарегистрированный на Сайте, имеет рейтинг, который рассчитывается в соответствии с
          <a href="/app/rules"> правилами Сервиса</a> и доступен всем пользователям Сайта и (или) Приложения.
        </p>
        <p>
          3.2 Отзывы пользователей о компетенции Эксперта и система рейтинга не могут рассматриваться как действия,
          направленные на нанесение ущерба деловой репутации Эксперта. Эксперт, регистрируясь в качестве такового в
          Сервисе, дает согласие пользователям Сервиса давать оценку своим знаниям и умениям, в том числе оценку
          негативного характера.
        </p>
        <p>
          3.4 По запросу Эксперта Лицензиар может удалить отзывы, являющиеся оскорбительными, явно необъективными либо
          не соответствующие действительности. Решение подобного рода конфликтных ситуаций остается на усмотрение
          Лицензиара.
        </p>
        <p>
          3.5 Эксперту, заполнившему все сведения о себе в личном кабинете, администрацией присваивается статус
          верифицированного, на что указывает графическое изображение в виде знака V голубого цвета в профиле Эксперта.
          Профиль верифицированного эксперта имеет преимущество в рейтинге экспертов перед профилем неверифицированного
          Эксперта.
        </p>
        <DocumentHeading>4. ПРАВИЛА ОКАЗАНИЯ КОНСУЛЬТАЦИЙ</DocumentHeading>

        <p>
          4.1 Взаимодействие Эксперта и Клиентов осуществляется вне рамок действия данного договора, но регулируется
          правилами Сервиса. Администрация Сервиса не является посредником, агентом, поверенным Эксперта и Клиента,
          кроме случаев, прямо указанных в данном договоре.
        </p>
        <p>
          4.2 Отношения Эксперта и Клиента по поводу консультации являются возмездным оказанием консультационных услуг
          Экспертом Клиенту.
        </p>
        <p>
          4.3 Клиент размещает в Сервисе запрос на консультацию. Эксперт вправе просматривать новые запросы на
          консультации по своей теме и предлагать Клиентам свои услуги с указанием стоимости консультации. Также запрос
          Клиента может быть направлен конкретному Эксперту. Эксперт не вправе включать свои личные контакты в
          предложение о консультации Клиенту до оплаты Клиентом консультации.
        </p>
        <p>
          4.4 В случае согласия Клиента на предложение Эксперта либо в случае согласия Эксперта дать консультацию
          Клиенту по его прямому запросу, договор на оказание консультационных услуг считается заключенным между
          Клиентом и Экспертом посредством Сервиса.
        </p>
        <p>
          4.5 Правилами Сервиса могут быть установлены требования к уникальности текста консультации, ее
          продолжительности, развернутости, точности, правильности.
        </p>
        <p>
          4.6 Эксперт получает от Клиента денежное вознаграждение за консультацию. Расчеты производятся в рублях
          Российской Федерации.
        </p>
        <p>
          4.7 Клиент, которого не устроило качество консультации, вправе потребовать возврата денежных средств за нее.
          Споры между Клиентом и Экспертом по поводу качества консультации разрешаются в соответствии с правилами
          Сервиса, решение о возврате денежных средств остается на усмотрение Сервиса и обжалованию не подлежит.
        </p>
        <p>
          4.8 В случае, если Эксперт получил оплату, но не подтвердил начало консультации, оплата подлежит возврату
          Клиенту.
        </p>
        <p>
          4.9 Эксперт обязан соблюдать конфиденциальность при даче консультации Клиенту, не разглашать третьим лицам
          детали консультации, в том числе сведения о частной жизни Клиента.
        </p>
        <DocumentHeading>5. ОПЛАТА УСЛУГ ЛИЦЕНЗИАРА</DocumentHeading>
        <p>
          5.1 Использование функционала Сервиса для ведения деятельности Эксперта является платным. Расчеты производятся
          в рублях Российской Федерации. Иностранные средства платежа, в том числе банковские карты, эмитированные
          иностранными банками и банковскими организациями, к расчету не принимаются.
        </p>
        <p>
          5.2 Эксперт уплачивает Лицензиару вознаграждение за услуги по использованию функционала Сервиса в размере
          процента от стоимости консультации, установленного каждому Эксперту индивидуально в личном кабинете.
        </p>
        <p>
          5.3 Лицензиар вправе на постоянной или временной основе установить конкретному Эксперту иной размер
          вознаграждения за услуги по использованию функционала Сервиса либо освободить его от уплаты вознаграждения в
          качестве бонуса. В таком случае размер вознаграждения отображается в личном кабинете Эксперта. Данные,
          содержащиеся в личном кабинете Эксперта о размере вознаграждения, являются приоритетными.
        </p>
        <p>
          5.4 Эксперт предоставляет Лицензиару право удерживать названное вознаграждение из суммы своего вознаграждения,
          оплачиваемой Клиентом через Сервис с использованием электронных расчетов в момент производства такого расчета.
        </p>
        <p>
          5.5 В качестве информационного сервиса о расчетах в личном кабинете Эксперта содержатся сведения об оказанных
          им консультациях, размере полученного вознаграждения за них, удержанного вознаграждения Лицензиара, отмене
          транзакций, о выводе денежных средств. Данные сведения носят исключительно информационный характер и не
          являются регистрами бухгалтерского учета. Перевод денежных средств между Экспертами не осуществляется.
        </p>
        <p>
          5.6 Расчеты между Экспертом и Клиентом могут осуществляться:
          <ol>
            <li>
              В рамках «безопасной сделки» согласно <a href="/trade">оферте стороннего сервиса </a>
              Тинькофф-банк, интегрированного с Сервисом – для Экспертов – самозанятых и физических лиц,
              интегрированного с Сервисом – для Экспертов – самозанятых и физических лиц.
            </li>
            <li>
              В случае заключения дополнительного соглашения с использованием агентских услуг Сервиса, предоставляющего
              Эксперту – индивидуальному предпринимателю либо юридическому лицу услуги по безналичному расчету с
              Клиентами посредством онлайн-эквайринга. В этом случае Сервис как агент Эксперта выдает Клиенту чек о
              произведенных расчетах, который направляется на электронную почту Клиента, указанную при регистрации в
              Сервисе.
            </li>
          </ol>
        </p>
        <p>
          5.7 Сроком исполнения всех денежных обязательств сторон считается поступление денежных средств на расчетный
          счет стороны – получателя денежных средств.
        </p>
        <p>
          5.8 Лицензиар не является источником дохода Эксперта, в связи с чем в соответствии со ст. 226 Налогового
          кодекса РФ, а также в соответствии с разъяснениями, содержащимися в Письме Министерства финансов от 24 февраля
          2016 г. № 03-04-06/10104; Письме Министерства финансов от 9 ноября 2015 г. № 03-04-05/64323 Лицензиар не
          выступает в качестве налогового агента Эксперта при перечислении ему денежных средств, Эксперт обязан
          самостоятельно отчитаться о полученном доходе и уплатить налог с полученной им от Клиента суммы вознаграждения
          в соответствии с требованиями действующего законодательства. Лицензиар не предоставляет кассовый чек при
          оказании консультации Экспертом – физическим лицом, поскольку в данном случае договор заключен между двумя
          физическими лицами.
        </p>
        <p>
          5.9 Эксперт – самозанятый (плательщик налога на профессиональный доход) обязан не позднее 7 (семи) календарных
          дней со дня получения денежных средств от Клиента сформировать чек в приложении «Мой налог» либо в
          интегрированных банковских приложениях и загрузить его в соответствующий раздел Сервиса для загрузки Клиентом.
          В случае неисполнения данной обязанности Лицензиар вправе списать стоимость консультации с личного счета
          Эксперта и возвратить ее Пользователю.
        </p>
        <p>
          5.10 Отчет Лицензиара о размере платы за услуги формируется в личном кабинете Эксперта ежемесячно и на
          бумажном носителе не предоставляется.
        </p>
      </Document>

      <PointsContainer>
        <Controller
          control={control}
          name="agencyAgreement"
          render={({ field: { onChange, value } }) => (
            <Point>
              <Checkbox checked={!!value} onChange={onChange} />
              <PointName>
                {t('form.agree')}
                <a href={`${OFERTA_LINK}`}>{t('form.agencyAgreementOffer')}</a>
              </PointName>
            </Point>
          )}
        />

        {/* <Controller
          control={control}
          name={'dataPolicyAgreement'}
          render={({ field: { onChange, value } }) => (
            <Point>
              <Checkbox checked={!!value} onChange={onChange} />
              <PointName>
                {t('form.agreeWith')}
                <a href="">
                  {t('form.dataProcessingPolicy')}
                </a>
              </PointName>
            </Point>
          )}
        /> */}
      </PointsContainer>

      <StyledButton disabled={!agencyAgreement} onClick={onContinue}>
        {buttonText || `${t('form.continue')}`}
      </StyledButton>
    </Container>
  );
};

const Container = styled.div``;

const Document = styled.div`
  font-size: 13px;
  color: var(--text);
  margin-bottom: 40px;
  p {
    margin-bottom: 16px;
  }
  a {
    color: var(--purple);
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
`;

const DocumentHeading = styled.div`
  text-align: center;
  font-weight: 700;
  margin: 24px 0;
  a {
    color: var(--purple);
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
`;

const Title = styled.h2`
  font-size: 28px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 40px;
  a {
    color: var(--purple);
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
`;

const Point = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 22px;
`;

const PointName = styled.div`
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--text);

  & > a {
    cursor: pointer;
    color: var(--purple);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

const PointsContainer = styled.div`
  margin-bottom: 40px;

  & > div:last-child {
    margin: 0;
  }
`;

const StyledButton = styled(Button)`
  width: 212px;
  margin-bottom: 60px;
`;
