import CheckMark from 'app/assets/svg/CheckMark';
import React, { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { ConsultationPriceForm } from 'routes/expert-profile/forms';
import { Category, Rubric, UserCategoryMeta } from 'shared/api';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { selectors } from 'store/ducks/profile';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

import { RubricOption } from '../rubric-option';
import { mappingCategory } from './mappingCategory';

interface RubricsProps {
  categories: Category[];
  rubrics: Rubric[];
  isUpcomingRubrics?: boolean;
}

export enum CategoryActions {
  add,
  delete,
  update,
}

export const Rubrics: FC<RubricsProps> = ({ categories, rubrics, isUpcomingRubrics }) => {
  const subCategoryMap = mappingCategory(categories);
  const userCategoryMap = new Map<number, UserCategoryMeta>();
  const { categories: selectedCategories } = useSelector(selectors.selectUser);
  const userCategories = selectedCategories || [];

  userCategories.forEach((c: UserCategoryMeta) => {
    userCategoryMap.set(c.categoryId, c);
  });

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [categoryId, setCategoryId] = useState<number>(0);
  const [category, setCategory] = useState<Category | null>(null);
  const [categoryAction, setCategoryAction] = useState<CategoryActions>(CategoryActions.add);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currenIds, setCurrenIds] = useState<number[]>([]);

  const onOpenModal = (categoryId: number, category: Category, action: CategoryActions) => () => {
    setCategoryAction(action);
    setCategoryId(categoryId);
    setCategory(category);
    setIsModalVisible(true);
  };

  const onCloseModal = () => {
    setIsModalVisible(false);
  };

  const renderRubrics = () =>
    rubrics.map(({ id, isUpcoming, name }) => {
      const rubricCategories = categories.filter((category) => category.rubrics.find((rubric) => rubric.id === id));
      console.log('-> rubricCategories', rubricCategories);

      return (
        <React.Fragment key={id}>
          {isUpcoming === !!isUpcomingRubrics && (
            <>
              <Header
                $isOpen={isOpen}
                onClick={() => {
                  if (!currenIds.includes(id)) {
                    setCurrenIds([...currenIds, id]);
                  } else {
                    setCurrenIds((currenIds) => currenIds.filter((item) => item !== id));
                  }
                  setIsOpen((s) => !s);
                }}
              >
                <Title>{name}</Title>
                <InfoContainer>
                  <PriceContainer />
                  <OpenHandlerContainer isOpen={currenIds.includes(id)}>
                    <CheckMark />
                  </OpenHandlerContainer>
                </InfoContainer>
              </Header>
              {currenIds.includes(id) && (
                <Table>
                  {rubricCategories?.map((cat) => (
                    <RubricOption
                      onOpenModal={onOpenModal}
                      category={cat}
                      subCategoryMap={subCategoryMap}
                      userCategoryMap={userCategoryMap}
                      key={cat.id}
                    />
                  ))}
                </Table>
              )}
            </>
          )}
        </React.Fragment>
      );
    });

  return (
    <>
      <Modal isVisible={isModalVisible} onClose={onCloseModal}>
        <ConsultationPriceForm
          onClose={onCloseModal}
          actionType={categoryAction}
          categoryId={categoryId}
          category={category}
        />
      </Modal>
      <Container>{renderRubrics()}</Container>
    </>
  );
};

const Container = styled.div`
  margin-top: 60px;
  justify-content: center;
  flex-direction: column;
  /* padding: 0 8px; */
  /* margin-bottom: 90px; */

  display: flex;

  ${CONDITION_DESKTOP} {
    /* padding: 0 95px 0 0; */
    justify-content: left;

    & > div {
      width: 526px;
    }
  }
`;
const Title = styled.h3`
  margin: 0;
  font-size: 17px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black2);
  padding: 15px 0;
`;

const Table = styled.div``;
const Header = styled.div<{ $isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #e6e9ea;

  & > svg {
    transition: 0.3s ease;
    transform: rotate(${({ $isOpen }) => ($isOpen ? 90 : 0)}deg);
  }

  :hover {
    cursor: pointer;
  }
`;

const OpenHandlerContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > svg {
    transition: 0.3s ease;
    transform: rotate(${({ isOpen }) => (isOpen ? 90 : 0)}deg);
  }

  ${CONDITION_DESKTOP} {
    min-width: 30px;
  }
`;
const InfoContainer = styled.div`
  display: flex;
`;
const PriceContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  ${CONDITION_DESKTOP} {
    min-width: 70px;
  }
`;
