import { useAppSelector } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { selectors } from 'store/ducks/profile';
import styled from 'styled-components';
import { ContractForm, ContractFormFinalStep, ContractFormProgressBar } from 'widgets/common';
import { MainLayout } from 'widgets/layouts';

export enum ContractFormSteps {
  variant,
  offer,
  props,
  final,
}

export interface StepsState {
  variant: boolean;
  offer: boolean;
  props: boolean;
}

export const Contract: FC = () => {
  const { t } = useTranslation('contract.page');
  const user = useAppSelector(selectors.selectUser);

  const head = {
    title: t('head.title'),
  };

  const [stepsState, setStepsState] = useState<StepsState>({
    variant: false,
    offer: false,
    props: false,
  });
  const [step, setStep] = useState<ContractFormSteps>(ContractFormSteps.variant);

  const changeStepHandle = (step: ContractFormSteps) => {
    setStep(step);
  };

  const changeProgressBarStateHandle = (data: Partial<StepsState>) => {
    setStepsState((s) => ({ ...s, ...data }));
  };

  return (
    <MainLayout head={head} hasFooter>
      <Container className="container">
        {step !== ContractFormSteps.final && (
          <>
            <ContractFormProgressBar
              step={step}
              stepsState={stepsState}
              setStepsState={changeProgressBarStateHandle}
              setStep={changeStepHandle}
            />

            <ContractForm
              step={step}
              changeStepHandle={changeStepHandle}
              changeProgressBarStateHandle={changeProgressBarStateHandle}
            />
          </>
        )}

        {step === ContractFormSteps.final && <ContractFormFinalStep user={user} />}
      </Container>
    </MainLayout>
  );
};

const Container = styled.div`
  &&& {
    padding: 0;
    margin-bottom: 60px;

    ${CONDITION_DESKTOP} {
      padding: 60px;
      margin: 0;
    }
  }
`;
