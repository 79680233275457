import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { api } from 'shared/api';
import { CreateAnswerNpsPollsDto, NpsPollTypeEnum } from 'shared/api/generated/api';
import { captureError } from 'shared/helpers/captureError';
import { getDiffenceBetweenTwoDatesInDays } from 'shared/helpers/time';
import { Button, Textarea } from 'shared/ui';
import { selectors as profileSelectors } from 'store/ducks';
import { actions, selectors } from 'store/ducks/app/slice';
import styled from 'styled-components';
import { Modal } from 'widgets/common';

const GetPollsThatTheUserHasNotCompleted = async () =>
  await api.V1NpsPollsApi.npsPollsControllerGetPollsThatTheUserHasNotCompleted();

export const Npspoll: FC = () => {
  const { t } = useTranslation('utils');
  const isVisible = useSelector(selectors.setIsVisiblePollModal);
  const user = useSelector(profileSelectors.profile.selectUser);
  const isAuthentication = useSelector(profileSelectors.profile.selectIsAuthentication);
  const [rating, setRating] = useState<number>();
  const [isDefaultPollTimeHasCome, setIsDefaultPollTimeHasCome] = useState<boolean>(false);
  const [isPoll, setIsPoll] = useState<boolean>(false);
  const [pollId, setPollId] = useState<number>(0);
  const dispatch = useAppDispatch();

  const initialMessage = '';

  const { control, handleSubmit, register, setValue } = useForm<CreateAnswerNpsPollsDto>({
    defaultValues: {
      text: initialMessage,
    },
  });

  useEffect(() => {
    if (isAuthentication) {
      GetPollsThatTheUserHasNotCompleted()
        .then((data) => {
          if (data.data.filter((c) => c.type === NpsPollTypeEnum.After2weeks).length !== 0) {
            const today = new Date();
            const userCreatedAt = new Date(user.createdAt);

            // const diffInHours = getDiffenceBetweenTwoDatesInHours(today, userCreatedAt);
            const diffInDays = getDiffenceBetweenTwoDatesInDays(today, userCreatedAt);
            if (diffInDays > 14) {
              // diff > 48
              dispatch(actions.showNpsPollModal());
              setIsDefaultPollTimeHasCome(true);
            }
          } else if (data.data.length !== 0) {
            const timeToLastPoll = new Date(data.data.slice(-1)[0].createdAt);
            const idLastPoll = data.data.slice(-1)[0].id;
            const timeToLastAnsweredPoll =
              user?.answerNpsPolls && new Date(user?.answerNpsPolls.slice(-1)[0]?.createdAt);

            if (timeToLastAnsweredPoll && timeToLastPoll.valueOf() - timeToLastAnsweredPoll.valueOf() > 0) {
              dispatch(actions.showNpsPollModal());
              setIsPoll(true);
              setPollId(idLastPoll);
            }
          }
        })
        .catch(() => console.error('Error getting pools'));
    }
  }, [isAuthentication, user]);

  const onSubmit = async ({ text, rating }: CreateAnswerNpsPollsDto) => {
    try {
      if (isDefaultPollTimeHasCome) {
        await api.V1NpsPollsApi.npsPollsControllerCreateAnswerForDefaultNpsPoll({
          text,
          rating,
        });
        dispatch(actions.hideNpsPollModal());
      }

      if (isPoll) {
        await api.V1NpsPollsApi.npsPollsControllerCreateAnswerNpsPoll(pollId, { text, rating });
        dispatch(actions.hideNpsPollModal());
      }
    } catch (e) {
      captureError(e);
      toast.error(t('somethingWrong'));
    }
  };

  const Ratings = [
    {
      rating: 0,
      background: '#fc2b48',
    },
    {
      rating: 1,
      background: '#ff612f',
    },
    {
      rating: 2,
      background: '#ff7f21',
    },
    {
      rating: 3,
      background: '#ff9810',
    },
    {
      rating: 4,
      background: '#ffb105',
    },
    {
      rating: 5,
      background: '#fbbc0c',
    },
    {
      rating: 6,
      background: '#fdbd04',
    },
    {
      rating: 7,
      background: '#e6bd07',
    },
    {
      rating: 8,
      background: '#c3c308',
    },
    {
      rating: 9,
      background: '#a3c413',
    },
    {
      rating: 10,
      background: '#79c724',
    },
  ];

  if (!isVisible) {
    return null;
  }

  return (
    <>
      <Modal isVisible={isVisible} width="min-content">
        <Container>
          <Title>
            {user.firstName}
            {t('npsPoll.heading')}
          </Title>
          <Description>{t('npsPoll.content')}</Description>
          <Note>{t('npsPoll.question')}</Note>
          <Values>
            {Ratings.map((item, index) => (
              <Rectangle
                {...register('rating')}
                $background={item.background}
                $isActive={index === rating}
                key={item.rating}
                onClick={() => {
                  setRating(item.rating);
                  setValue('rating', item.rating);
                }}
              >
                {item.rating}
              </Rectangle>
            ))}
          </Values>
          <Captions>
            <LowValue>{t('npsPoll.bad')}</LowValue>
            <HighValue>{t('npsPoll.good')}</HighValue>
          </Captions>
          {rating != null && (
            <InnerContainer>
              <StyledNote>{t('npsPoll.textareaHeading')}</StyledNote>
              <Controller
                control={control}
                name="text"
                render={({ field: { value, onChange }, fieldState: { error } }) => (
                  <Textarea
                    onChange={(e) => {
                      onChange(e);
                      // if (e.target.value.length <= 1000) {
                      //   ;
                      // }
                    }}
                    $width="100%"
                    isShowCounterAndLabel={false}
                    value={value}
                    label={' '}
                    placeholder={t('npsPoll.textareaPlaceholder')}
                  />
                )}
              />
              <ButtonWrapper>
                <StyledButton block onClick={handleSubmit(onSubmit)}>
                  {t('npsPoll.button')}
                </StyledButton>
              </ButtonWrapper>
            </InnerContainer>
          )}
        </Container>
      </Modal>
    </>
  );
};

const Container = styled.div``;

const Title = styled.h3`
  font-size: 19px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 15px;
  white-space: pre-line;
`;

const Description = styled.p`
  font-size: 13px;
  line-height: 20px;
  margin-bottom: 20px;
  color: #232832;
`;

const Note = styled.p`
  font-size: 15px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 30px;
  white-space: pre-line;
`;

const StyledNote = styled(Note)`
  margin-bottom: 20px;
`;

const Values = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 10px;
`;

const Rectangle = styled.div<{ $background: string; $isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 35px;
  height: 35px;
  border-width: 0px;
  color: white;
  border-radius: 8px;
  background-color: ${({ $background, $isActive }) => ($isActive ? 'var(--purple)' : $background)};
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.8)};

  :before {
    content: '';
    width: 41px;
    height: 41px;
    position: absolute;
    border-radius: 10px;
    border: ${({ $isActive }) => ($isActive ? '2px solid var(--purple)' : 'none')};
  }

  :hover {
    cursor: pointer;
    opacity: 1;
  }
`;

const Captions = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 11px;
  line-height: 15px;
  font-weight: 500;
`;

const LowValue = styled.div`
  color: #ff3366;
`;

const HighValue = styled.div`
  color: #24af1e;
`;

const InnerContainer = styled.div`
  margin-top: 40px;
`;

const ButtonWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  span {
    padding: 2px 4px;
    margin: 0;
  }
`;
