import { useScrollToErrorInput } from 'hooks/useScrollToErrorInput';
import React, { ChangeEvent, FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ContractFormSteps } from 'routes/contract/contract';
import { datePattern, digitsPattern, emailPattern, fioPattern } from 'shared/helpers/patterns';
import { Button, Input, PhotoInput } from 'shared/ui';
import { ContractFormValues } from 'widgets/common';

interface IpFormProps {
  changeStepHandle: (step: ContractFormSteps) => void;
  changeStep?: () => void;
}

export const IpForm: FC<IpFormProps> = ({ changeStepHandle, changeStep }) => {
  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext<ContractFormValues>();

  useScrollToErrorInput(errors);

  type FileInputsName = 'registrationInPassportPhoto' | 'selfWithPassport' | 'passportPhoto';

  const onChangePhoto = (name: FileInputsName) => (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      const file = files.item(0);
      if (file) {
        setValue(name, file);
        clearErrors(name);
      }
    }
  };

  const onSubmit = (values: ContractFormValues) => {
    // TODO: insert logic
    if (changeStep) {
      changeStep();
    }
    changeStepHandle(ContractFormSteps.final);
  };

  return (
    <>
      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          pattern: { value: fioPattern, message: 'Некорректный формат' },
        }}
        name="fullName"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input name="fullName" value={value} label="ФИО" onChange={onChange} required error={error?.message} />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
          pattern: { value: digitsPattern, message: 'Только цифры' },
        }}
        name="INN"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input name="INN" value={value} label="ИНН" required onChange={onChange} error={error?.message} />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
          pattern: { value: digitsPattern, message: 'Только цифры' },
        }}
        name="OGRNIP"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input name="OGRNIP" value={value} label="ОГРНИП" required onChange={onChange} error={error?.message} />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
          pattern: { value: datePattern, message: 'Некорректная дата' },
        }}
        defaultValue=""
        name="OGRNIPDate"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            value={value}
            name="OGRNIPDate"
            label="Дата выдачи ОГРНИП"
            placeholder="01.01.2021"
            required
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
        }}
        defaultValue=""
        name="addressOfRegistration"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            value={value}
            name="addressOfRegistration"
            label="Адрес регистрации по месту жительства"
            required
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="physicalAddress"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            name="physicalAddress"
            value={value}
            label="Физический адрес"
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
        }}
        defaultValue=""
        name="phone"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            name="phone"
            value={value}
            label="Контактный номер телефона"
            required
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="email"
        defaultValue=""
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          pattern: { value: emailPattern, message: 'Некорректный Email' },
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            name="email"
            value={value ? value.replace(/\s/g, '').trim() : value}
            label="Электронная почта для уведомлений"
            onChange={onChange}
            required
            error={error?.message}
          />
        )}
      />

      {/* <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
        }}
        defaultValue={''}
        name={'bankName'}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input value={value} label={'Наименование банка'} onChange={onChange} required error={error?.message} />
        )}
      /> */}

      {/* <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
          pattern: { value: digitsPattern, message: 'Только цифры' },
        }}
        name={'BIK'}
        defaultValue={''}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input value={value} label={'БИК'} required onChange={onChange} error={error?.message} />
        )}
      /> */}

      {/* <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
          pattern: { value: digitsPattern, message: 'Только цифры' },
        }}
        defaultValue={''}
        name={'paymentAccount'}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input value={value} label={'Расчетный счёт'} required onChange={onChange} error={error?.message} />
        )}
      /> */}

      <Controller
        control={control}
        name="passportPhoto"
        rules={{
          required: { value: true, message: 'Обязательно' },
        }}
        render={({ fieldState: { error } }) => (
          <PhotoInput
            prefix="passportPhoto"
            label="Выбрать"
            name="passportPhoto"
            width="50%"
            title="Фото второй и третьей страницы паспорта"
            required
            error={error?.message}
            onChange={onChangePhoto('passportPhoto')}
            sizeLimitMb={15}
            largeSizeError="Файл больше 15 МБ"
          />
        )}
      />

      <Controller
        control={control}
        name="selfWithPassport"
        rules={{
          required: { value: true, message: 'Обязательно' },
        }}
        render={({ fieldState: { error } }) => (
          <PhotoInput
            prefix="selfWithPassport"
            label="Выбрать"
            name="selfWithPassport"
            width="50%"
            title="Селфи с второй и третьей страницей паспорта (должно быть видно паспорт и лицо целиком)"
            required
            error={error?.message}
            onChange={onChangePhoto('selfWithPassport')}
            sizeLimitMb={15}
            largeSizeError="Файл больше 15 МБ"
          />
        )}
      />

      <Controller
        control={control}
        name="registrationInPassportPhoto"
        render={({ fieldState: { error } }) => (
          <PhotoInput
            prefix="registrationInPassportPhoto"
            label="Выбрать"
            width="50%"
            name="registrationInPassportPhoto"
            title="Фото паспорта, страница с пропиской"
            error={error?.message}
            onChange={onChangePhoto('registrationInPassportPhoto')}
            sizeLimitMb={15}
            largeSizeError="Файл больше 15 МБ"
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
          pattern: { value: digitsPattern, message: 'Только цифры' },
        }}
        defaultValue=""
        name="paymentAccount"
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            name="paymentAccount"
            value={value}
            label="Номер банковской карты"
            required
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Button onClick={handleSubmit(onSubmit)}>Продолжить</Button>
    </>
  );
};
