import { CheckWhite } from 'app/assets/svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect } from 'react';
import { User, UserStepsEnum } from 'shared/api';
import { CONDITION_DESKTOP, CONDITION_MOBILE } from 'shared/common/constants';
import { Steps } from 'shared/common/types';
import { getNumberFromString } from 'shared/helpers/number';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';
import { ContractFormValues } from 'widgets/common';

export type ProfileStepsState = Record<Steps, boolean>;
interface ContractFormProgressBarProps {
  user: User;
  step: Steps;
  setStep: (s: Steps) => void;
  formValues?: Pick<ContractFormValues, 'variant' | 'agencyAgreement'>;
}

const stepsMapping: Record<UserStepsEnum, Partial<Steps>> = {
  [UserStepsEnum._1start]: Steps.photo,
  [UserStepsEnum._2photo]: Steps.about,
  [UserStepsEnum._3about]: Steps.themes,
  [UserStepsEnum._4theme]: Steps.variant,
  [UserStepsEnum._5profile]: Steps.variant,
  [UserStepsEnum._6doc]: Steps.variant,
  [UserStepsEnum._7rek]: Steps.done,
  [UserStepsEnum._0checkboxexpert]: Steps.photo,
  [UserStepsEnum._8done]: Steps.photo,
};

export const ProgressBar: FC<ContractFormProgressBarProps> = ({ user, step, setStep, formValues }) => {
  const { t } = useTranslation('profile.page');
  const userSteps = useAppSelector(selectors.profile.selectUserSteps);
  const dispatch = useAppDispatch();
  const lastContentChangeRequest = useAppSelector(selectors.profile.selectLastContentChangeRequest);

  const isPhotoStepPassed = (): boolean =>
    userSteps?.includes(UserStepsEnum._2photo) && !!lastContentChangeRequest.avatarUrl;

  const isAboutSelfStepPassed = (): boolean =>
    userSteps?.includes(UserStepsEnum._3about) && !!lastContentChangeRequest.aboutSelf;

  const isThemeStepPassed = (): boolean => userSteps?.includes(UserStepsEnum._4theme) && !!user?.categories?.length;

  const isVariantStepPassed = (): boolean => userSteps?.includes(UserStepsEnum._5profile) && !!formValues?.variant;

  const isPropsStepPassed = (): boolean =>
    isVariantStepPassed() && userSteps?.includes(UserStepsEnum._6doc) && !!formValues?.agencyAgreement;

  const isContractAccepted = (): boolean =>
    userSteps?.includes(UserStepsEnum._7rek) && user.isContractAccepted && isPropsStepPassed();

  const onPointClick = (desiredStep: Steps) => () => {
    switch (desiredStep) {
      case Steps.props:
        if (isVariantStepPassed()) {
          setStep(desiredStep);
          dispatch(actions.profile.setLastStep(desiredStep));
        }
        break;
      case Steps.offer:
        if (isPropsStepPassed()) {
          setStep(desiredStep);
          dispatch(actions.profile.setLastStep(desiredStep));
        }
        break;
      default:
        setStep(desiredStep);
        return dispatch(actions.profile.setLastStep(desiredStep));
    }
  };

  useEffect(() => {
    if (userSteps?.length) {
      const sortedSteps = [...userSteps]?.sort((a, b) => getNumberFromString(a) - getNumberFromString(b));
      onPointClick(stepsMapping[sortedSteps.slice(-1)[0] as UserStepsEnum])();
    } else {
      onPointClick(stepsMapping[UserStepsEnum._2photo])();
    }
  }, []);

  const renderCircles = (step: Steps, selectedStep: Steps) => {
    if (step === selectedStep) {
      return <ActiveCircle />;
    }

    let isCircleChecked = false;
    switch (selectedStep) {
      case Steps.photo:
        isCircleChecked = isPhotoStepPassed();
        break;
      case Steps.about:
        isCircleChecked = isAboutSelfStepPassed();
        break;
      case Steps.themes:
        isCircleChecked = isThemeStepPassed();
        break;
      case Steps.variant:
        isCircleChecked = isVariantStepPassed();
        break;
      case Steps.props:
        isCircleChecked = isPropsStepPassed();
        break;
      case Steps.offer:
        isCircleChecked = isContractAccepted();
        break;

      default:
        break;
    }

    if (isCircleChecked) {
      return (
        <PassedCircle>
          <CheckWhite />
        </PassedCircle>
      );
    }
    return <MissedCircle />;
  };
  return (
    <Container>
      <Progress>
        <Point onClick={onPointClick(Steps.photo)}>
          <PointName $active={step === Steps.photo}>{t('steps.photo')}</PointName>
          {renderCircles(step, Steps.photo)}
        </Point>

        <Point onClick={onPointClick(Steps.about)}>
          <PointName $active={step === Steps.about}>{t('steps.about')}</PointName>
          {renderCircles(step, Steps.about)}
        </Point>

        <Point onClick={onPointClick(Steps.themes)}>
          <PointName $active={step === Steps.themes}>{t('steps.themes')}</PointName>
          {renderCircles(step, Steps.themes)}
        </Point>

        <Point onClick={onPointClick(Steps.variant)}>
          <PointName $active={step === Steps.variant}>{t('steps.profile')}</PointName>
          {renderCircles(step, Steps.variant)}
        </Point>

        <Point onClick={onPointClick(Steps.props)}>
          <PointName $active={step === Steps.props}>{t('steps.contract')}</PointName>
          {renderCircles(step, Steps.props)}
        </Point>

        <Point onClick={onPointClick(Steps.offer)}>
          <PointName $active={step === Steps.offer}>{t('steps.credentials')}</PointName>
          {renderCircles(step, Steps.offer)}
        </Point>

        <Line />
      </Progress>
    </Container>
  );
};

const Container = styled.div`
  width: 526px;
  ${CONDITION_DESKTOP} {
    margin: 0 auto;
  }
  ${CONDITION_MOBILE} {
    width: unset;
    max-width: unset;
  }
`;

const Progress = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const Point = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0px;
  border-radius: 12px;
  transition: 0.3s ease background-color;
  min-width: 85px;

  ${CONDITION_MOBILE} {
    min-width: 50px;
  }

  &:hover {
    background-color: #f7f6ff;
    cursor: pointer;
  }
`;

const PointName = styled.div<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  min-height: 30px;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({ $active }) => ($active ? 'var(--purple)' : 'var(--text)')};
  margin-bottom: 13px;

  ${CONDITION_MOBILE} {
    font-size: 9px;
    font-weight: 500;
  }
`;

// const Circle = styled.div<{ $active?: boolean }>`
//   border-radius: 50%;
//   width: 15px;
//   height: 15px;
//   border: 2px solid var(--purple);
//   background-color: ${({ $active }) => ($active ? 'var(--purple)' : 'var(--white)')};
//   z-index: 1;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `;

const PassedCircle = styled.div<{ $active?: boolean }>`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border: 2px solid var(--purple);
  background-color: var(--purple);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ActiveCircle = styled.div<{ $active?: boolean }>`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border: 2px solid var(--purple);
  background-color: var(--white);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MissedCircle = styled.div<{ $active?: boolean }>`
  border-radius: 50%;
  width: 15px;
  height: 15px;
  border: 2px solid var(--gray3);
  background-color: var(--white);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Line = styled.div`
  position: absolute;
  height: 1px;
  background-color: var(--purple);
  opacity: 0.3;
  width: 65%;

  left: 50%;
  bottom: 22px;
  transform: translateX(-50%);

  ${CONDITION_DESKTOP} {
    width: 85%;
  }

  ${CONDITION_MOBILE} {
    /* width: 135%;
    left: 70%; */
    width: calc(100% - 35px);
  }
`;
