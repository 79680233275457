import { unwrapResult } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useAppDispatch } from 'hooks/redux';
import { useScrollToErrorInput } from 'hooks/useScrollToErrorInput';
import { useTranslation } from 'next-i18next';
import React, { ChangeEvent, FC, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';
import ym from 'react-yandex-metrika';
import { ContractFormSteps } from 'routes/contract/contract';
import { api } from 'shared/api';
import { ENVIRONMENT_TYPE } from 'shared/common/constants';
import { ContractSignSmsCodePayload } from 'shared/common/types';
import { captureError } from 'shared/helpers/captureError';
import {
  datePattern,
  emailPattern,
  fioPattern,
  innPathPattern,
  passportNumberPattern,
  passportSeriesPattern,
} from 'shared/helpers/patterns';
import { uploadFile } from 'shared/helpers/uploadFile';
import { Button, Input, InputDate, InputPhone, PhotoInput } from 'shared/ui';
import { sendSelfEmployedContract } from 'store/ducks/profile/actions';
import styled from 'styled-components';
import { ContractFormValues } from 'widgets/common';
import { ContractSignCodeModal } from 'widgets/modals';

dayjs.extend(utc);

interface IndividualFormProps {
  changeStepHandle: (step: ContractFormSteps) => void;
  changeStep?: () => void;
}

export const SelfEmployedForm: FC<IndividualFormProps> = ({ changeStepHandle, changeStep }) => {
  const {
    handleSubmit,
    setValue,
    control,
    clearErrors,
    trigger,
    watch,
    formState: { errors },
  } = useFormContext<ContractFormValues>();
  const numberWatch = watch('phone');
  const [isSignSmsModalOpen, setIsSignSmsModalOpen] = useState(false);

  const { t: u } = useTranslation('utils');

  type FileInputsName = 'registrationInPassportPhoto' | 'selfWithPassport' | 'passportPhoto';

  const dispatch = useAppDispatch();

  const onChangePhoto = (name: FileInputsName) => (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      const file = files.item(0);
      if (file) {
        setValue(name, file);
        clearErrors(name);
      }
    }
  };

  const sendAnalyticsData = () => {
    if (ENVIRONMENT_TYPE === 'production') {
      ym('reachGoal', ['signdocsms']);
    }
  };

  const handleSendSignSms = async () => {
    const isFormOk = await trigger();

    if (!isFormOk) {
      return;
    }

    try {
      await api.V1ContractsApi.contractsControllerSendSignSmsCode();
      setIsSignSmsModalOpen(true);
      sendAnalyticsData();
    } catch (e: any) {
      toast.error(e.message || 'Что-то пошло не так...');
    }
  };

  const handleSubmitSignSms = async (payload: ContractSignSmsCodePayload) => {
    setIsSignSmsModalOpen(false);

    await handleSubmit((values) => onSubmit({ ...values, ...payload }))();
  };

  useScrollToErrorInput(errors);

  const onSubmit = async (values: ContractFormValues & ContractSignSmsCodePayload) => {
    try {
      const selfWithPassport = await uploadFile(values.selfWithPassport, u);

      const res = await dispatch(
        sendSelfEmployedContract({
          signSmsCode: values.smsCode,
          timestampEnterSignSmsCode: values.timestampEnterSmsCode,
          timestampAcceptExpertOffer: values.agencyAgreementTimestamp,
          timestampAcceptPrivacyPolicy: values.dataPolicyAgreementTimestamp,
          payload: {
            birthDate: dayjs(values.birthday, 'DD.MM.YYYY').format('YYYY-MM-DD'),
            birthPlace: values.birthPlace,
            contactPhone: values.phone,
            email: values.email.trim(),
            fullName: values.fullName,
            inn: values.INN,
            passportIssuedBy: values.whoPassportIssued,
            passportNumber: values.passportNumber,
            passportSeries: values.passportSeries,
            passportWhenGiven: dayjs(values.whenPassportIssued, 'DD.MM.YYYY').format('YYYY-MM-DD'),
            physicalAddress: values.physicalAddress,
            residencePlaceRegistrationAddress: values.addressOfRegistration,
            selfieWithPassportSecondAndThirdPageUrl: selfWithPassport,
          },
        }),
      );
      unwrapResult(res);
      if (changeStep) {
        changeStep();
      } else {
        changeStepHandle(ContractFormSteps.final);
      }
    } catch (e: any) {
      toast.error(e.message || u('failedSendContract'));
      captureError(e);
    }
  };

  return (
    <>
      <ContractSignCodeModal
        number={numberWatch}
        isVisible={isSignSmsModalOpen}
        onSubmitSmsCode={handleSubmitSignSms}
      />
      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          pattern: { value: fioPattern, message: 'Некорректный формат' },
        }}
        name="fullName"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            name="fullName"
            value={value}
            label="ФИО"
            placeholder="Иванов Иван Иванович"
            onChange={onChange}
            required
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательно' },
          pattern: { value: datePattern, message: 'Дата должна быть в формате 31.01.2000' },
        }}
        name="birthday"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <InputDate
            value={value}
            name="birthday"
            label="Дата рождения"
            onChange={onChange}
            required
            error={error?.message}
            placeholder="01.05.1992"
          />
        )}
      />

      <Controller
        control={control}
        name="birthPlace"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            value={value}
            name="birthPlace"
            label="Место рождения"
            placeholder="г. Москва"
            onChange={onChange}
            error={error?.message}
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          pattern: { value: passportSeriesPattern, message: 'Должно быть 4 цифры' },
        }}
        name="passportSeries"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            value={value}
            type="text"
            name="passportSeries"
            label="Серия паспорта"
            onChange={onChange}
            required
            error={error?.message}
            placeholder="4444"
            maxLength={4}
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          pattern: { value: passportNumberPattern, message: 'Должно быть 6 цифр' },
        }}
        name="passportNumber"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            value={value}
            label="Номер паспорта"
            name="passportNumber"
            type="text"
            onChange={onChange}
            required
            error={error?.message}
            placeholder="555555"
            maxLength={6}
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          pattern: { value: datePattern, message: 'Дата должна быть в формате 31.01.2000' },
        }}
        name="whenPassportIssued"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <InputDate
            value={value}
            label="Когда выдан"
            name="whenPassportIssued"
            onChange={onChange}
            required
            error={error?.message}
            placeholder="01.01.2021"
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
        }}
        name="whoPassportIssued"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            value={value}
            name="whoPassportIssued"
            label="Кем выдан"
            onChange={onChange}
            required
            error={error?.message}
            placeholder="Отделом внутренних дел Октябрьского округа города Архангельска"
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
        }}
        name="addressOfRegistration"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            value={value}
            name="addressOfRegistration"
            label="Адрес регистрации по месту жительства"
            onChange={onChange}
            required
            error={error?.message}
            placeholder="Кемеровская обл., гор. Ленинск-Кузнецкий, ул. Суворова, д. 9, кв. 1"
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          pattern: { value: innPathPattern, message: 'Должно быть 10 или 12 цифр' },
        }}
        name="INN"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            value={value}
            name="INN"
            label="ИНН"
            required
            onChange={onChange}
            error={error?.message}
            placeholder="123456789012"
            maxLength={12}
          />
        )}
      />

      <Controller
        control={control}
        name="physicalAddress"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            value={value}
            name="physicalAddress"
            label="Физический адрес"
            onChange={onChange}
            error={error?.message}
            placeholder="Кемеровская обл., гор. Ленинск-Кузнецкий, ул. Суворова, д. 9, кв. 1"
          />
        )}
      />

      <Controller
        control={control}
        rules={{
          required: { value: true, message: 'Обязательное поле' },
        }}
        name="phone"
        defaultValue=""
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <InputPhone
            value={value}
            label="Контактный номер телефона"
            type="text"
            name="phone"
            onChange={onChange}
            required
            error={error?.message}
            placeholder="+79088034444"
          />
        )}
      />

      <Controller
        control={control}
        name="email"
        defaultValue=""
        rules={{
          required: { value: true, message: 'Обязательное поле' },
          pattern: { value: emailPattern, message: 'Некорректный Email' },
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            value={value ? value.replace(/\s/g, '').trim() : value}
            name="email"
            label="Электронная почта для уведомлений"
            onChange={onChange}
            required
            error={error?.message}
            placeholder="example@gmail.com"
          />
        )}
      />

      <Title>
        <span>Селфи с второй и третьей страницей паспорта (должно быть видно паспорт и лицо целиком)</span>
        <Required>*</Required>
      </Title>
      <Controller
        control={control}
        name="selfWithPassport"
        rules={{
          required: { value: true, message: 'Обязательно' },
        }}
        render={({ fieldState: { error } }) => (
          <PhotoInput
            prefix="selfWithPassport"
            label="Выбрать"
            name="selfWithPassport"
            width="50%"
            error={error?.message}
            onChange={onChangePhoto('selfWithPassport')}
            sizeLimitMb={15}
            largeSizeError="Файл больше 15 МБ"
          />
        )}
      />

      <Button type="button" onClick={handleSendSignSms}>
        Подписать договор SMS-сообщением
      </Button>
    </>
  );
};

const Title = styled.span`
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: var(--text);
  margin-bottom: 22px;
  display: block;
`;

const Required = styled.span`
  color: var(--purple);
`;
