import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { Category, Rubric } from 'shared/api';
import { CONDITION_DESKTOP, CONDITION_MOBILE } from 'shared/common/constants';
import styled from 'styled-components';
import { Rubrics } from 'widgets/common';

type ThemeSelectProps = {
  categories: Category[];
  rubrics: Rubric[];
};

export const ThemeSelect: FC<ThemeSelectProps> = ({ categories, rubrics }) => {
  const { t } = useTranslation('profile.page');
  return (
    <>
      <Title>{t('consultationTopics')}</Title>
      <Note>{t('consultationTopicsNote')}</Note>
      <Rubrics categories={categories} rubrics={rubrics} />
    </>
  );
};

const Note = styled.div`
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: var(--gray);

  & > a {
    color: var(--purple);
    text-decoration: underline;
    :hover {
      text-decoration: none;
    }
  }

  ${CONDITION_DESKTOP} {
    width: 526px;
  }
`;

const Title = styled.h2`
  margin: 0;
  font-size: 25px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--black2);
  padding-bottom: 10px;
  margin-top: 90px;

  ${CONDITION_DESKTOP} {
    width: 526px;
  }

  ${CONDITION_MOBILE} {
    font-size: 24px;
    margin-top: 0px;
  }
`;
