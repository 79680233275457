import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/redux';
import { useTranslation } from 'next-i18next';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { User } from 'shared/api';
import { CONDITION_DESKTOP } from 'shared/common/constants';
import { getClearTime, getFullYear } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import { emailPattern } from 'shared/helpers/patterns';
import { Button, Input, InputPhoneWithCode, Select } from 'shared/ui';
import { actions } from 'store/ducks';
import styled from 'styled-components';

import { useDebounce } from '../../../hooks/useDebounce';
import { generateMouthOptions } from './generateMouthOptions';

interface SettingFormValues {
  firstName: string;
  lastName: string;
  number: string;
  email: string;
  birthDay?: {
    day: { label: string; value: string };
    mouth: { label: string; value: string };
    year: { label: string; value: string };
  };
}

const optionsDays: any[] = [];
const optionsYears: any[] = [];

for (let i = 1; i < 32; i++) {
  optionsDays.push({ value: `${i}`, label: `${i}` });
}
const year = new Date().getFullYear();
for (let i = year - 100; i < year; i++) {
  optionsYears.push({ value: String(i), label: String(i) });
}
optionsYears.reverse();

interface SettingFormProps {
  user: User;
}

export const SettingForm: FC<SettingFormProps> = ({ user }) => {
  const { t } = useTranslation('profile.page');
  const { t: error } = useTranslation('errors.messages');
  const { t: u } = useTranslation('utils');

  const mouthOptions = generateMouthOptions(t);

  let birthDate:
    | {
        day: { value: string; label: string };
        mouth: { value: string; label: string };
        year: { value: string; label: string };
      }
    | undefined;

  let sliceDate:
    | {
        day: { value: string; label: string };
      }
    | undefined;

  if (user.birthDate) {
    const fullYear = getFullYear(user.birthDate);
    sliceDate = {
      day: { value: fullYear.day, label: fullYear.day },
    };
    if (sliceDate.day.value.length > 2) {
      birthDate = {
        day: { value: sliceDate.day.value.slice(0, 2), label: sliceDate.day.value.slice(0, 2) },
        mouth: { value: fullYear.mouth, label: mouthOptions.find((o) => o.value === fullYear.mouth)?.label || '' },
        year: { value: fullYear.year, label: fullYear.year },
      };
    } else {
      birthDate = {
        day: { value: fullYear.day, label: fullYear.day },
        mouth: { value: fullYear.mouth, label: mouthOptions.find((o) => o.value === fullYear.mouth)?.label || '' },
        year: { value: fullYear.year, label: fullYear.year },
      };
    }
  } else {
    birthDate = undefined;
  }

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    reset,
    watch,
    clearErrors,
    setError,
  } = useForm<SettingFormValues>({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      number: user.phone,
      birthDay: birthDate,
      email: user.email,
    },
  });

  const dispatch = useAppDispatch();
  const unTrimEmail = watch('email');
  const email = unTrimEmail?.replace(/\s/g, '').trim();

  const [emailForCheck] = useDebounce(email, 500);

  useEffect(() => {
    const checkEmailIsUniq = async () => {
      try {
        const action = await dispatch(actions.app.checkEmailIsUniqAsync(emailForCheck));
        const isUniq = unwrapResult(action);
        if (!isUniq) {
          setError('email', { message: u('emailIsNoUniq') });
        } else {
          clearErrors('email');
        }
      } catch (e: any) {
        captureError(e);
        setError('email', { message: u('incorrectEmail') });
      }
    };
    if (Boolean(emailForCheck) && user.email !== email) {
      checkEmailIsUniq();
    }
  }, [emailForCheck]);

  const onSubmit = async ({ birthDay, firstName, lastName, email }: SettingFormValues) => {
    let day;
    let mouth;
    let year;
    let date;

    if (birthDay?.day && birthDay.mouth && birthDay.year) {
      day = parseInt(birthDay.day.value, 10);
      mouth = parseInt(birthDay.mouth.value, 10);
      year = parseInt(birthDay.year.value, 10);
      date = new Date();
      date.setFullYear(year, mouth, day);
    }

    const sameEmail = user.email === email;

    try {
      const res = await dispatch(
        actions.profile.fetchUpdateIamProfile({
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email: sameEmail ? undefined : email.replace(/\s/g, '').trim(),
          birthDate: date && getClearTime(date),
        }),
      );
      const unwrappedResult = unwrapResult(res);
      if (unwrappedResult) {
        toast.success(t('saveInfo'));
        await dispatch(actions.profile.sendVerificationEmailAsync());

        reset({ ...unwrappedResult });
        dispatch(actions.profile.fetchMe());
      }
    } catch (e: any) {
      captureError(e);
      setError('email', { message: u('incorrectEmail') });
      // toast.error(e.message || t('notSuccessSaveInfo'));
      reset();
    }
  };

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title>{t('title')}</Title>
        <Controller
          name="firstName"
          control={control}
          rules={{
            required: { value: true, message: error('required') },
            maxLength: { value: 45, message: u('maxLength') },
          }}
          render={({ field, fieldState }) => (
            <Input {...field} label={t('form.firstName')} error={fieldState.error?.message} />
          )}
        />

        <Controller
          name="lastName"
          control={control}
          rules={{
            required: { value: true, message: error('required') },
            maxLength: { value: 45, message: u('maxLength') },
          }}
          render={({ field, fieldState }) => (
            <Input {...field} label={t('form.lastName')} error={fieldState.error?.message} />
          )}
        />
        <Label>{t('form.birthDay.title')}</Label>
        <BirthDayField>
          <Controller
            name="birthDay.day"
            control={control}
            render={({ field, fieldState }) => (
              <Select
                {...field}
                options={optionsDays}
                placeholder={t('form.birthDay.day')}
                error={fieldState.error?.message}
                instanceId="select-birth-day"
              />
            )}
          />
          <Controller
            control={control}
            name="birthDay.mouth"
            render={({ field, fieldState }) => (
              <Select
                {...field}
                placeholder={t('form.birthDay.mouth')}
                options={mouthOptions}
                error={fieldState.error?.message}
                instanceId="select-birth-mouth"
              />
            )}
          />
          <Controller
            control={control}
            name="birthDay.year"
            render={({ field, fieldState }) => (
              <Select
                {...field}
                options={optionsYears}
                placeholder={t('form.birthDay.year')}
                error={fieldState.error?.message}
                instanceId="select-birth-year"
              />
            )}
          />
        </BirthDayField>

        <Controller
          name="number"
          control={control}
          render={({ field, fieldState }) => (
            <InputPhoneWithCode {...field} label={t('form.number')} error={fieldState.error?.message} disabled />
          )}
        />

        <Controller
          name="email"
          control={control}
          rules={{
            pattern: {
              value: emailPattern,
              message: error('incorrectEmail'),
            },
            required: { value: true, message: error('required') },
          }}
          render={({ field, fieldState }) => (
            <Input
              {...field}
              value={field.value ? field.value.replace(/\s/g, '').trim() : field.value}
              label={t('form.email')}
              placeholder={t('form.email')}
              error={fieldState.error?.message}
              successMessage={user.isEmailVerified && !fieldState.error?.message ? t('form.emailVerified') : undefined}
            />
          )}
        />
        <Label>{t('form.emailReason')}</Label>

        <Button type="submit" disabled={!isDirty}>
          {t('form.submit')}
        </Button>
      </Form>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 90px;

  ${CONDITION_DESKTOP} {
    width: calc(270px * 2 + 20px);
  }
`;

const Form = styled.form`
  max-width: 420px;
  width: 100%;
  margin: 0 10px;

  & > div {
    margin-top: 25px;
  }

  & > button {
    margin-top: 60px;
    width: 100%;

    ${CONDITION_DESKTOP} {
      width: unset;
    }
  }
`;

const BirthDayField = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 25px;

  ${CONDITION_DESKTOP} {
    flex-direction: row;
    gap: 0;
  }

  & > div {
    width: 100%;

    ${CONDITION_DESKTOP} {
      width: 30%;
    }
  }
`;

const Title = styled.h2`
  text-align: left;
  margin: 0;
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 15px;
  color: var(--gray);
  margin-top: 10px;
  display: block;
`;
