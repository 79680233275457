import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/redux';
import { useAutofocus } from 'hooks/useAutofocus';
import { useTranslation } from 'next-i18next';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Category } from 'shared/api';
import { onKeyDownNumber } from 'shared/helpers';
import { captureError } from 'shared/helpers/captureError';
import { pricePattern } from 'shared/helpers/patterns';
import { Button, Input } from 'shared/ui';
import { fetchAddUserToCategory, fetchUpdatePriceCategory } from 'store/ducks/profile/actions';
import { selectUserCategoryMetaByCategoryId } from 'store/ducks/profile/selectors';
import styled from 'styled-components';
import { CategoryActions } from 'widgets/common';

interface ConsultationPriceFormValues {
  price: string;
}

interface ConsultationPriceFormProps {
  categoryId: number;
  category: Category | null;
  actionType: CategoryActions;
  onClose: () => void;
}

export const ConsultationPriceForm: FC<ConsultationPriceFormProps> = ({
  categoryId,
  actionType,
  onClose,
  category,
}) => {
  const { t } = useTranslation('profile.page');
  const { t: tError } = useTranslation('errors.messages');

  const userCategoryMeta = useSelector(selectUserCategoryMetaByCategoryId(categoryId));

  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: {
      dirtyFields: { price },
    },
    setFocus,
  } = useForm<ConsultationPriceFormValues>({
    defaultValues: {
      price: '',
    },
  });
  useAutofocus<ConsultationPriceFormValues>('price', setFocus);

  const onSubmit = async (values: ConsultationPriceFormValues) => {
    const price = parseInt(values.price, 10);
    let res;
    switch (actionType) {
      case CategoryActions.add:
        try {
          res = await dispatch(
            fetchAddUserToCategory({
              categoryId,
              price,
            }),
          );
          if (unwrapResult(res)) {
            // toast.success(t('saveInfo'));
            onClose();
          }
        } catch (e) {
          captureError(e);
          toast.error(t('notSuccessSaveInfo'));
        }
        break;
      case CategoryActions.update:
        try {
          res = await dispatch(
            fetchUpdatePriceCategory({
              dto: {
                categoryId,
                price,
              },
              category: userCategoryMeta!,
            }),
          );
          if (unwrapResult(res)) {
            toast.success(t('saveInfo'));
            onClose();
          }
        } catch (e) {
          captureError(e);
          toast.error(t('notSuccessSaveInfo'));
        }
        break;
      default:
        onClose();
        break;
    }
  };

  return (
    <Container>
      <Title>{t('consultationPriceFormTitle')}</Title>
      <Note>{t('consultationPriceFormNote', { rubric: category?.name })}</Note>
      <InputContainer>
        <Controller
          control={control}
          name="price"
          rules={{
            pattern: { value: pricePattern, message: 'incorrectData' },
            validate: {
              isNumber: (v) => !!parseInt(v, 10) || 'mustBeANumber',
              minLength: (v) => v.length > 1 || 'tooSmall',
            },
          }}
          render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
            <Input
              type="number"
              placeholder={t('consultationPriceFormPlaceholder')}
              value={value}
              ref={ref}
              onChange={(e) => {
                if (e.target.value.length < 6) {
                  onChange(e);
                }
              }}
              onKeyDown={onKeyDownNumber}
              error={error?.message && tError(error?.message)}
            />
          )}
        />
        <Currency>{t('currency')}</Currency>
      </InputContainer>
      <Button disabled={!price} onClick={handleSubmit(onSubmit)}>
        {t('consultationPriceFormButton')}
      </Button>
    </Container>
  );
};

const Title = styled.div`
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  padding-bottom: 15px;
`;

const Note = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  padding-bottom: 30px;
  max-width: 260px;
`;

const Container = styled.div`
  & > button {
    margin-top: 15px;
    width: 100%;
  }
`;

const InputContainer = styled.div`
  position: relative;
`;
const Currency = styled.span`
  position: absolute;
  top: 25px;
  right: 20px;
  color: var(--gray);
  transform: translateY(-50%);
`;
