import { unwrapResult } from '@reduxjs/toolkit';
import { Camera } from 'app/assets/svg';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useAutofocus } from 'hooks/useAutofocus';
import { usePrepareImageToUpload } from 'hooks/usePrepareImageToUpload';
import { useVkMetrics } from 'hooks/useVkMetrics';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ym from 'react-yandex-metrika';
import { ExpertStepDtoStepEnum } from 'shared/api';
import { ENVIRONMENT_TYPE } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { captureError, coverImageStyle, getRandomAvatar } from 'shared/helpers';
import { emailPattern } from 'shared/helpers/patterns';
import { Button, Checkbox, Input, Tip } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';

import { useDebounce } from '../../../../hooks/useDebounce';
import { AuthFormSteps } from '../../types';

interface AuthFinalStepFormProps {
  closeModal: () => void;
  setStep: Dispatch<SetStateAction<AuthFormSteps>>;
}

interface FinalStepFormValues {
  firstName: string;
  lastName: string;
  email: string;
  avatar: string;
}

export const AuthFinalStepForm: FC<AuthFinalStepFormProps> = ({ closeModal, setStep }) => {
  const { control, watch, handleSubmit, setError, clearErrors, setFocus } = useForm<FinalStepFormValues>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      avatar: '',
    },
  });
  const [firstName, lastName, unTrimEmail] = watch(['firstName', 'lastName', 'email']);
  const email = unTrimEmail.replace(/\s/g, '').trim();
  const [emailForCheck] = useDebounce(email, 500);

  const dispatch = useAppDispatch();
  const router = useRouter();
  const { t } = useTranslation('header.component');
  const { t: u } = useTranslation('utils');
  const [isCheckExpert, setIsCheckExpert] = useState(false);

  const vkMetrics = useVkMetrics();

  const [isDefaultImage, setDefaultImage] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string>();
  const [file, setFile] = useState<File>();
  const { selectFile, uploadFileToCloud } = usePrepareImageToUpload();

  const isRedirectPaymentModal = useAppSelector(selectors.app.selectIsRedirectPaymentModal);
  const isFreeChatRedirect = useAppSelector(selectors.app.selectIsFreeChatRedirectInfo);
  const isFreeThemeChatRedirect = useAppSelector(selectors.app.selectIsFreeThemeRedirectInfo);

  useAutofocus<FinalStepFormValues>('firstName', setFocus);

  useEffect(() => {
    const checkEmailIsUniq = async () => {
      const action = await dispatch(actions.app.checkEmailIsUniqAsync(emailForCheck));
      const result = unwrapResult(action);

      if (!result) {
        setError('email', { message: u('emailIsNoUniq') });
      } else {
        clearErrors('email');
      }
    };
    if (emailForCheck) {
      checkEmailIsUniq();
    }
  }, [emailForCheck]);

  const sendWelcomeAnalyticsData = () => {
    if (ENVIRONMENT_TYPE === 'production') {
      vkMetrics?.goal('complete_registration');
      ym('reachGoal', ['welcome']);
    }
  };

  const sendFreeChatAnalyticsData = () => {
    if (ENVIRONMENT_TYPE === 'production') {
      ym('reachGoal', ['askfreechat']);
      if (window && window.gtag) {
        window.gtag('event', 'askfreechat');
      }
    }
  };

  const sendExpertStepAnalyticsData = () => {
    if (ENVIRONMENT_TYPE === 'production') {
      ym('reachGoal', [ExpertStepDtoStepEnum._0checkboxexpert]);
    }
  };

  const onSubmit = async ({ firstName, lastName, email }: FinalStepFormValues) => {
    try {
      let avatarUrl: string | undefined;

      if (file) {
        avatarUrl = await uploadFileToCloud(file);
      } else {
        avatarUrl = imgSrc;
      }
      const res = await dispatch(
        //isExpert: isCheckExpert, //TODO: need fix CON-33
        actions.profile.fetchUpdateIamProfile({
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email: email.replace(/\s/g, '').trim(),
          ...(avatarUrl && { avatarUrl }),
        }),
      );

      if (unwrapResult(res)) {
        toast.success(t('saveInfo'));
        dispatch(actions.profile.sendVerificationEmailAsync());
        sendWelcomeAnalyticsData();
        setStep(AuthFormSteps.number);
        closeModal();
        if (isCheckExpert) {
          sendExpertStepAnalyticsData();
          dispatch(actions.profile.addUserStepAsync({ step: ExpertStepDtoStepEnum._0checkboxexpert }));
          await dispatch(actions.profile.updateUserSetExpert());
        }
        if (isRedirectPaymentModal) {
          dispatch(actions.app.showPaymentModal());
          return;
        }
        if (isFreeThemeChatRedirect.isFreeThemeRedirect) {
          dispatch(actions.app.setFreeThemeChatRedirectInfo({ isSignedUp: true, isFreeThemeRedirect: false }));
          return;
        }
        if (isFreeChatRedirect) {
          sendFreeChatAnalyticsData();
          dispatch(actions.app.setFreeChatAskInfo(true));
          return;
        }
        router.replace(isCheckExpert ? routes.profileSteps : routes.topics);
      }
    } catch (e: any) {
      captureError(e);
      setError('email', { message: u('incorrectEmail') });
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    try {
      selectFile(files, ({ readerResult, file }) => {
        setFile(file);
        setImgSrc(readerResult);
      });
    } catch (error: any) {
      captureError(e);
      toast.error(error.message);
    }
  };

  if (!isDefaultImage) {
    setImgSrc(getRandomAvatar());
    setDefaultImage(true);
  }

  return (
    <Container>
      <Tip text={u('imageSize')}>
        <Avatar htmlFor="avatar" $url={imgSrc}>
          <CameraContainer isVisible>
            <Camera />
          </CameraContainer>
        </Avatar>
      </Tip>

      <Title>
        {firstName.length > 0 || lastName.length > 0
          ? `${firstName.trim()} ${lastName.trim()}`
          : t('authModal.yourName')}
      </Title>
      <Note>{t('authModal.enterName')}</Note>
      <Controller
        name="firstName"
        control={control}
        rules={{
          required: { value: true, message: u('required') },
          maxLength: { value: 45, message: u('maxLength') },
        }}
        render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
          <Input
            value={value}
            onChange={onChange}
            error={error?.message}
            placeholder={t('authModal.firstName')}
            ref={ref}
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        rules={{
          required: { value: true, message: u('required') },
          maxLength: { value: 45, message: u('maxLength') },
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input value={value} onChange={onChange} error={error?.message} placeholder={t('authModal.lastName')} />
        )}
      />
      <Controller
        name="email"
        control={control}
        rules={{
          pattern: { value: emailPattern, message: u('incorrectEmail') },
          required: { value: true, message: u('required') },
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Input
            value={value ? value.replace(/\s/g, '').trim() : value}
            style={{ marginBottom: 10 }}
            onChange={onChange}
            error={error?.message}
            placeholder={t('authModal.email')}
          />
        )}
      />
      <Check checked={isCheckExpert} onChange={() => setIsCheckExpert(!isCheckExpert)}>
        {t('authModal.registerAsExpert')}
      </Check>
      <Button
        disabled={firstName.length === 0 || lastName.length === 0 || email.length === 0}
        onClick={handleSubmit(onSubmit)}
      >
        {t('authModal.enter')}
      </Button>
      <input id="avatar" onChange={onChange} type="file" accept="image/png, image/jpeg" style={{ display: 'none' }} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & > button {
    width: 100%;
    margin-top: 15px;
  }

  & > div > input {
    margin: 5px 0;
  }
`;

const Avatar = styled.label<{ $url?: string }>`
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  border-radius: 50%;
  background-color: ${({ $url }) => ($url ? 'transparent' : 'var(--purple)')};

  ${({ $url }) => ({ ...coverImageStyle($url) })}
  :hover {
    cursor: pointer;
  }
`;

const Title = styled.div`
  font-size: 19px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  padding-bottom: 15px;
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Note = styled.div`
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.54;
  letter-spacing: normal;
  text-align: center;
  color: var(--text);
  padding-bottom: 30px;
  max-width: 260px;
`;

const CameraContainer = styled.div<{ isVisible: boolean }>`
  opacity: ${(props) => (props.isVisible ? '1' : '0')};
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  transition: opacity 0.5s ease, background-color 0.5s ease;
  opacity: 1;
  background-color: var(--purple);
  position: absolute;
  bottom: 0;
  right: 0;
`;

const Check = styled(Checkbox)`
  & {
    align-self: flex-start;
    width: 100%;
  }

  div:last-child {
    font-size: 13px;
    text-align: start;
    line-height: 20px;
    color: var(--paleText);
  }
`;
