import SvgBackArrowPurple from 'app/assets/svg/BackArrowPurple';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { FC, useState } from 'react';
import { CONDITION_DESKTOP, DESKTOP, HEADER_HEIGHT_DESKTOP, HEADER_HEIGHT_MOBILE } from 'shared/common/constants';
import { routes } from 'shared/common/routes';
import { Spinner } from 'shared/ui';
import styled from 'styled-components';
import { ExitStepModal } from 'widgets/modals';

interface ProfileStepLayoutProps {
  isLoading?: boolean;
}

export const ProfileStepLayout: FC<ProfileStepLayoutProps> = ({ children, isLoading = true }) => {
  const { t } = useTranslation('header.component');
  const [isShowModalExit, setIsShowModalExit] = useState(false);
  const router = useRouter();

  const handleClick = () => {
    router.replace(`${routes.profile}?activeTab=expertProfile`);
    setIsShowModalExit(false);
  };

  return (
    <Layout>
      <Wrapper>
        <Header>
          <BackLink>
            <IconWrapper>
              <SvgBackArrowPurple />
            </IconWrapper>
            <div role="button" onClick={() => setIsShowModalExit(true)} tabIndex={0} onKeyPress={() => {}}>
              {t('returnProfile')}
            </div>
          </BackLink>
        </Header>
        <Container className="container">{isLoading ? <StyledSpinner size="50px" /> : <div>{children}</div>}</Container>
      </Wrapper>
      <ExitStepModal isVisible={isShowModalExit} onClose={() => setIsShowModalExit(false)} handleClick={handleClick} />
    </Layout>
  );
};

const Layout = styled.div`
  min-height: 100vh;
  min-height: var(--v-height);
`;

const Wrapper = styled.div`
  width: 100%;
`;

const StyledSpinner = styled(Spinner)`
  margin-top: 150px;
`;

const BackLink = styled.div`
  padding: 30px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 15px;

  div {
    color: var(--purple);
    cursor: pointer;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  z-index: 1000;
  height: ${HEADER_HEIGHT_MOBILE};
  top: 0;
  background-color: var(--white);
  width: 100%;
  border-bottom: 1px solid var(--gray5);
  ${CONDITION_DESKTOP} {
    min-width: ${DESKTOP}px;
    padding-left: 162px;
    height: ${HEADER_HEIGHT_DESKTOP};
  }
`;

const Container = styled.div`
  &&& {
    flex-direction: column-reverse;
    width: 100vw;
    margin: 0 auto;
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: center;

    ${CONDITION_DESKTOP} {
      max-width: ${DESKTOP}px;
      margin-top: 30px;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
    }
  }
`;

const IconWrapper = styled.div`
  width: 10px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;
