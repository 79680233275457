import { unwrapResult } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks/redux';
import { useAutofocus } from 'hooks/useAutofocus';
import { useTranslation } from 'next-i18next';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ym from 'react-yandex-metrika';
import { ENVIRONMENT_TYPE, OFERTA_LINK, POLICYWEB_LINK, TRADE_LINK } from 'shared/common/constants';
import { clearTel } from 'shared/helpers';
import { Button, Checkbox, InputPhoneWithCode } from 'shared/ui';
import { actions, selectors } from 'store/ducks';
import styled from 'styled-components';

import { AuthFormSteps, AuthFormValues } from '../../types';

interface AuthNumberFormProps {
  setStep: Dispatch<SetStateAction<AuthFormSteps>>;
  currentPhone: string;
  setCurrentPhone: Dispatch<SetStateAction<string>>;
}

const isNotFilledTel = (t: any) => (v: string) => {
  const clearedTel = clearTel(v);
  if (clearedTel.length === 0) {
    return t('authModal.numberRequired');
  }
  return clearedTel.length < 6 ? t('authModal.incorrectNumber') : undefined;
};

const sendDataToMetrics = () => {
  if (ENVIRONMENT_TYPE === 'production') {
    ym('reachGoal', ['Landing_ex-Modal_window-get_sms']);
    if (window && window.gtag) {
      window.gtag('event', 'Landing_ex-Modal_window-get_sms');
    }
    // window.fbq('trackCustom', 'Landing_ex-Modal_window-get_sms');
  }
};

export const AuthNumberForm: FC<AuthNumberFormProps> = ({ setStep, currentPhone, setCurrentPhone }) => {
  const { t } = useTranslation('header.component');
  const { control, handleSubmit, watch, setError, setFocus, getValues } = useFormContext<AuthFormValues>();
  const isWait = useSelector(selectors.app.selectAuthModalIsWait);
  const dispatch = useAppDispatch();

  useAutofocus<AuthFormValues>('number', setFocus);

  const onGetSms = async ({ number, agreement }: AuthFormValues) => {
    sendDataToMetrics();
    setCurrentPhone(clearTel(number));
    if (localStorage.getItem('phone') !== getValues('number')) {
      dispatch(actions.app.setCounter(60));
    }
    localStorage.setItem('phone', number);
    if (currentPhone === clearTel(number)) {
      if (isWait) {
        setStep(AuthFormSteps.smsCode);
        return;
      }
    } else if (isWait) {
      dispatch(actions.app.setWait(false));
    }
    if (agreement) {
      try {
        const action = await dispatch(actions.profile.sendSmsAsync({ phone: clearTel(number) }));
        const result = unwrapResult(action);

        setStep(AuthFormSteps.smsCode);
        if (ENVIRONMENT_TYPE !== 'production') {
          toast.info(result, { autoClose: false });
          sendDataToMetrics();
        }
      } catch (error: any) {
        if (error.statusCode === 429) {
          setError('code', { message: t('authModal.notSendCode') });
          setStep(AuthFormSteps.smsCode);
        } else {
          toast.error(error.message || t('authModal.somethingWrong'));
        }
      }
    }
  };

  return (
    <>
      <Controller
        name="number"
        control={control}
        rules={{
          validate: isNotFilledTel(t),
        }}
        render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
          <InputPhoneWithCode onChange={onChange} value={value} error={error?.message} ref={ref} />
        )}
      />
      <Button disabled={!watch('agreement')} onClick={handleSubmit(onGetSms)}>
        {t('authModal.getSms')}
      </Button>

      <AgreementContainer>
        <Controller
          control={control}
          name="agreement"
          render={({ field }) => <Checkbox checked={field.value} onChange={field.onChange} />}
        />
        <Agreement>
          {t('authModal.agreementFirstPart')}
          <a href={`${OFERTA_LINK}`} target="_blank" rel="noreferrer">
            {t('authModal.termsOfOffer')}
          </a>
          {', '}
          <a href={`${TRADE_LINK}`} target="_blank" rel="noreferrer">
            {t('authModal.termsOfDeal')}
          </a>
          {t('authModal.agreementSecondPart')}
          <a href={`${POLICYWEB_LINK}`} target="_blank" rel="noreferrer">
            {t('authModal.termsOfPolicy')}
          </a>
        </Agreement>
      </AgreementContainer>
    </>
  );
};

const Agreement = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: var(--paleText);
  max-width: 230px;

  & > a {
    font-weight: bold;
    text-decoration: none;
    color: var(--paleText);

    :hover {
      text-decoration: underline;
    }
  }
`;
const AgreementContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;
